import { themeGet } from '@styled-system/theme-get';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body, #root, input, select {
    font-family: ${themeGet('font')};
    overflow-x: hidden;
  }

  #__next {
    position: relative;
  }

  body {
    font-family: ${themeGet('font')};
    line-height: 1.5;
  }

  b { font-weight: ${themeGet('olist.fontWeight.bold')}; }

  button { font-family: ${themeGet('font')}; }
`;
